
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.description {
    color:#fff;
    h2{
        font-size: 35px;
        font-weight: 800;
        line-height: 45px;
        letter-spacing: 0em;
    

    }
    p {
        font-size: 16px;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: 0px;
        text-align: center;
        @media screen and (min-width:731px) {
            font-size: 20px;
        }
    }

    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        background-image: url("/assets/images/waste.jpg");
        z-index: -1;
    }
}
